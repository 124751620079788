import { useEffect } from "react";
import { useAppSelector } from "../hooks/storeHooks";
import { getTrips } from "../services/tripService";
import { useNavigate } from "react-router-dom";
import { Routes } from "../config/Routes";
import { Card, IconButton } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";

const Trips: React.FC = () => {
  const { isLoading, trips } = useAppSelector((s) => s.tripReducer);
  const navigate = useNavigate();

  useEffect(() => {
    getTrips();
  }, []);

  return (
    <div className="p-2 h-full bg-gray-100">
      <IconButton
        className="mb-2"
        onClick={() => {
          navigate(Routes.Home);
        }}
      >
        <ArrowBack />
      </IconButton>

      <div className="text-3xl font-bold mb-5">Viajes</div>
      {isLoading ? (
        <div className="text-center">Cargando</div>
      ) : (
        <Card className="rounded-lg shadow overflow-auto p-1">
          <div>
            {/* Vista móvil */}
            {trips.map((trip) => (
              <div
                key={trip._id}
                className={`mb-4 p-2 shadow rounded-lg ${
                  trip.status === "Completado"
                    ? "bg-green-500 text-white"
                    : "bg-white"
                }`}
                onClick={() => {
                  navigate(`${Routes.Trips}/${trip._id}`);
                }}
              >
                <div>
                  <span className="font-semibold">Cliente:</span>{" "}
                  {trip.customer?.name || "-"}
                </div>
                <div>
                  <span className="font-semibold">Origen:</span> {trip.origin}
                </div>
                <div>
                  <span className="font-semibold">Destino:</span>{" "}
                  {trip.destination}
                </div>
                <div>
                  <span className="font-semibold">Fecha:</span>{" "}
                  {new Date(trip.programmedAt).toLocaleDateString()}
                </div>
                <div>
                  <span className="font-semibold">Hora Programada:</span>{" "}
                  {new Date(trip.programmedAt).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                  })}
                </div>
                <div>
                  <span className="font-semibold">Estado:</span> {trip.status}
                </div>
              </div>
            ))}
          </div>
        </Card>
      )}
    </div>
  );
};

export default Trips;
