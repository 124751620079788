import axios from "axios";
import { AuthActions } from "../reducers/AuthReducer";
import store from "../store";
import client from "./client";

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (name: string, password: string) => {
  try {
    console.log("login", name, password);
    const {
      data: { token, user },
    } = await axios.post<{ token: string; user: User }>(
      `${API_URL}auth/login`,
      {
        name,
        password,
      }
    );
    if (user.role !== "driver") {
      throw new Error("You are not a driver");
    }
    store.dispatch({ type: AuthActions.SetToken, payload: token });
    store.dispatch({ type: AuthActions.SetUser, payload: user });
    localStorage.setItem("token", token);
    localStorage.setItem("user", JSON.stringify(user));
  } catch (error: any) {
    console.log(error);
  }
};

export const getInfo = async () => {
  try {
    const {
      data: { user },
    } = await client.get(`${API_URL}auth/show`);
    store.dispatch({ type: AuthActions.SetUser, payload: user });
    localStorage.setItem("user", JSON.stringify(user));
  } catch (error: any) {
    console.log(error);
  }
};

export const initLogin = async () => {
  const token = localStorage.getItem("token");

  if (token) {
    try {
      client.get(`${API_URL}auth/validate`, {
        headers: {
          "x-auth-token": token,
        },
      });
      store.dispatch({ type: AuthActions.SetToken, payload: token });
      store.dispatch({
        type: AuthActions.SetUser,
        payload: JSON.parse(localStorage.getItem("user") || "{}"),
      });
    } catch (error) {
      localStorage.removeItem("token");

      store.dispatch({ type: AuthActions.SetToken, payload: null });
      store.dispatch({ type: AuthActions.SetUser, payload: null });
    }
  }
};
