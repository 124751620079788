import { Button, IconButton } from "@mui/material";
import { useAppSelector } from "../hooks/storeHooks";
import { useNavigate, useParams } from "react-router-dom";
import { updateTrip } from "../services/tripService";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AWS from "aws-sdk";
import { useEffect, useState } from "react";
import mergeFileLists from "merge-file-lists";
import { resizeImage } from "../utils/resizeImage";

AWS.config.update({
  accessKeyId: "AKIA3SZLZ5QCGEDDBBM6",
  secretAccessKey: "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",
  region: "sa-east-1",
  signatureVersion: "v4",
});

const S3_BUCKET_NAME = "pinetrack-bucket";

const Trip: React.FC = () => {
  const { trips } = useAppSelector((s) => s.tripReducer);
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const trip = trips.find((t: { _id: string }) => t._id === id);
  const [showUpload, setShowUpload] = useState(false);
  const [images, setImages] = useState<FileList | null>(null);
  const [uploadedImages, setUploadedImages] = useState<string[]>([]);

  const handleUpload = (e: { target: { files: FileList | null } }) => {
    var newFiles = e.target.files;
    if (images != null && e.target.files != null) {
      newFiles = mergeFileLists(images, e.target.files);
    }

    setImages(newFiles);
  };
  const handleAmazonUpload = async () => {
    setShowUpload(true);
    var imageLocations: string[] = [];
    if (images != null) {
      const s3 = new AWS.S3();
      const uploadPromises = Array.from(images).map(async (image) => {
        const resizedImage = await resizeImage(image, 800, 800);

        const params = {
          Bucket: S3_BUCKET_NAME,
          Key: `${Date.now()}.${image.name}`,
          Body: resizedImage,
        };
        const { Location } = await s3.upload(params).promise();
        console.log("enviado a Amazon");
        imageLocations.push(Location);
        console.log(imageLocations);
      });
      await Promise.all(uploadPromises);
      const imagesToUpdate = trip!.photos ? trip!.photos : [];
      imageLocations = [...imagesToUpdate, ...imageLocations];
      await updateTrip(
        {
          photos: imageLocations,
        },
        id!
      );
      console.log("enviado a la base de datos");
      setImages(null);
      setUploadedImages(imageLocations);
    }
    setShowUpload(false);
  };
  useEffect(() => {
    if (trip === undefined) {
      navigate("/trips");
    } else if (trip!.photos) {
      setUploadedImages(trip!.photos);
    }
  }, []);
  if (trip === undefined) {
    return null;
  } else
    return (
      <div className="flex justify-center items-center h-screen bg-gray-100">
        <div className="p-4 flex flex-col gap-2 bg-white rounded-lg shadow-md">
          <div className="flex justify-between items-center">
            <h2 className="text-2xl font-bold">Información del viaje</h2>
          </div>
          <div className="flex flex-col gap-2">
            <div className="flex flex-row gap-2 items-center">
              <div className="font-bold w-24">Origen:</div>
              <div>{trip!.origin}</div>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <div className="font-bold w-24">Destino:</div>
              <div>{trip!.destination}</div>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <div className="font-bold w-24">Fecha:</div>
              <div>{`${new Date(trip!.programmedAt).getDate()}/${
                new Date(trip!.programmedAt).getMonth() + 1
              }/${new Date(trip!.programmedAt).getFullYear()}`}</div>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <div className="font-bold w-24">Hora:</div>
              <div>{`${new Date(trip!.programmedAt).getHours()}:${new Date(
                trip!.programmedAt
              )
                .getMinutes()
                .toString()
                .padStart(2, "0")}`}</div>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <div className="font-bold w-24">Cliente:</div>
              <div>{trip!.customer.name}</div>
            </div>
            <div className="flex flex-col gap-2 items-center">
              <div className="font-bold w-24">Imagenes cargadas:</div>
              {uploadedImages.map((photo) => {
                return (
                  <img
                    style={{ padding: "10px" }}
                    width={100}
                    height={100}
                    src={photo}
                    alt="imagen"
                  />
                );
              })}
            </div>

            <div className="p-5 flex flex-col items-center">
              <h4>Ingrese imagenes: </h4>
              {images != null &&
                Array.from(images).map((item) => {
                  return (
                    <span>
                      <img
                        style={{ padding: "10px" }}
                        width={100}
                        height={100}
                        src={URL.createObjectURL(item)}
                        alt="imagen"
                      />
                    </span>
                  );
                })}
            </div>
            <div className="flex flex-row gap-2 items-center justify-center">
              <input
                accept="image/*"
                id="icon-button-file"
                type="file"
                hidden
                onChange={handleUpload}
              />
              <label htmlFor="icon-button-file">
                <IconButton
                  color="primary"
                  aria-label="upload picture"
                  component="span"
                >
                  <CameraAltIcon fontSize="large" color="primary" />
                </IconButton>
              </label>
              <IconButton
                color="error"
                onClick={() => {
                  setImages(null);
                }}
              >
                <DeleteForeverIcon fontSize="large" color="error" />
              </IconButton>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <Button
                variant="contained"
                onClick={handleAmazonUpload}
                disabled={images == null || showUpload}
              >
                Enviar imagenes
              </Button>
            </div>
            <div className="flex flex-row gap-2 items-center">
              <Button
                variant="contained"
                color="secondary"
                onClick={() => {
                  navigate("/trips");
                }}
              >
                Volver
              </Button>
              <Button
                variant="contained"
                disabled={showUpload}
                onClick={async () => {
                  setShowUpload(true);
                  await updateTrip(
                    { finishedAt: new Date(Date.now()), status: "Completado" },
                    id!
                  );
                  navigate("/trips");
                  setShowUpload(false);
                }}
              >
                Terminar Viaje
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
};

export default Trip;
