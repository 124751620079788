import React from "react";

const Layout: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="h-screen md:flex overscroll-y-contain">
      <div className="flex-1 bg-archiGrey overflow-auto h-full ">
        {children}
      </div>
    </div>
  );
};

export default Layout;
