import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Routes } from "../config/Routes";
import { useAppSelector } from "../hooks/storeHooks";
import { AuthActions } from "../reducers/AuthReducer";
import store from "../store";

const Home: React.FC = () => {
  const user = useAppSelector((s) => s.authReducer.user);
  const navigate = useNavigate();

  return (
    <div>
      <div className="home">
        <img
          src={"bienvenido.png"}
          className="welcome-image mt-10"
          alt="Bienvenido"
        />
        <div className="welcome-title">¡Bienvenid@!!</div>
        <div className="welcome-subtitle"> {user ? user.name : ""} </div>
        <div className="mt-5 flex-col flex gap-4">
          <Button
            variant="contained"
            onClick={() => {
              navigate(Routes.Trips);
            }}
          >
            Ir a Viajes
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              store.dispatch({ type: AuthActions.DelToken, payload: null });
              localStorage.removeItem("token");
              navigate(Routes.Login);
            }}
          >
            Cerrar Sesion
          </Button>
        </div>
      </div>
    </div>
  );
};
export default Home;
