import { BrowserRouter, Route, Routes as AppRoutes } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material";
import { initLogin } from "./services/authService";
import { useEffect, useState } from "react";
import { mdiCircleSlice1 } from "@mdi/js";
import Icon from "@mdi/react";
import { Routes } from "./config/Routes";
import ProtectedRoute from "./components/ProtectedRoute";
import Layout from "./components/Layout";
import Login from "./components/Login";
import Home from "./components/Home";
import Trips from "./components/Trips";
import Trip from "./components/Trip";

function App() {
  const theme = createTheme({
    palette: {
      background: {
        default: "#E5E5E5",
      },
      primary: {
        main: "#BF549A",
      },
      secondary: {
        main: "#ED6E11",
      },
    },
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      setLoading(true);
      initLogin().finally(() => setLoading(false));
    };
    init();
  }, []);
  return (
    <BrowserRouter>
      <ThemeProvider theme={theme}>
        <Layout>
          {loading ? (
            <div className="h-full flex justify-center items-center">
              <div className="animate-spin">
                <Icon size={6} path={mdiCircleSlice1}></Icon>
              </div>
            </div>
          ) : (
            <AppRoutes>
              <Route
                path={Routes.Home}
                element={<ProtectedRoute element={<Home />}></ProtectedRoute>}
              ></Route>
              <Route
                path={Routes.Trips}
                element={
                  <ProtectedRoute element={<Trips></Trips>}></ProtectedRoute>
                }
              ></Route>
              <Route
                path={Routes.Trip}
                element={
                  <ProtectedRoute element={<Trip></Trip>}></ProtectedRoute>
                }
              ></Route>
              <Route path={Routes.Login} element={<Login />}></Route>
            </AppRoutes>
          )}
        </Layout>
      </ThemeProvider>
    </BrowserRouter>
  );
}

export default App;
