interface State {
  isLoading: boolean;
  trips: Trip[];
}

const initialState: State = {
  isLoading: false,
  trips: [],
};

export enum TripActions {
  RequestTrips = "REQUEST_TRIPS",
  ReceiveTrips = "RECEIVE_TRIPS",
}

const reducer = (
  state: State = initialState,
  { type, payload }: { type: TripActions; payload: any }
): State => {
  switch (type) {
    case TripActions.RequestTrips:
      return { ...state, isLoading: true };
    case TripActions.ReceiveTrips:
      return { ...state, trips: payload, isLoading: false };
    default:
      return state;
  }
};

export default reducer;
