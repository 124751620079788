import { TripActions } from "../reducers/TripReducer";
import store from "../store";
import axios from "./client";
const API_URL = process.env.REACT_APP_API_URL;

export const getTrips = async () => {
  try {
    const user = JSON.parse(localStorage.getItem("user") || "{}");
    const driverId = user.driver;
    store.dispatch({ type: TripActions.RequestTrips, payload: {} });
    const { data: trips } = await axios.get<Trip[]>(
      `${API_URL}trips/${driverId}`
    );
    store.dispatch({ type: TripActions.ReceiveTrips, payload: trips });
  } catch (error: any) {
    console.log(error);
    store.dispatch({ type: TripActions.ReceiveTrips, payload: [] });
  }
};

export const updateTrip = async (trip: Partial<Trip>, id: string) => {
  try {
    await axios.patch<Trip>(`${API_URL}trips/${id}`, trip);
    await getTrips();
  } catch (error: any) {
    console.log(error);
  }
};
